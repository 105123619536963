@import 'breakpoints';

@mixin typography-h10 {
  font-family: var(--theme-font-family-base);
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1rem;
}

@mixin typography-h9 {
  font-family: var(--theme-font-family-base);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 1.125rem;
}

@mixin typography-h8 {
  font-family: var(--theme-font-family-base);
  font-weight: 600;
  font-size: 1rem;
  line-height: 1.25rem;
}

@mixin typography-h7 {
  font-family: var(--theme-font-family-base);
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.5rem;
}

@mixin typography-h6 {
  font-family: var(--theme-font-family-base);
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.75rem;
}

@mixin typography-h5 {
  font-family: var(--theme-font-family-base);
  font-weight: 600;
  font-size: 1.75rem;
  line-height: 2.125rem;
}

@mixin typography-h4 {
  font-family: var(--theme-font-family-base);
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.375rem;
}

@mixin typography-h3 {
  font-family: var(--theme-font-family-base);
  font-size: 2.625rem;
  font-weight: 600;
  line-height: 3rem;
}

@mixin typography-h2 {
  font-family: var(--theme-font-family-base);
  font-size: 3.25rem;
  font-weight: 600;
  line-height: 3.875rem;
}

@mixin typography-h1 {
  font-family: var(--theme-font-family-base);
  font-size: 3.75rem;
  font-weight: 600;
  line-height: 4.125rem;
}

@mixin typography-text-6 {
  font-family: var(--theme-font-family-base);
  font-size: 0.5rem;
  font-weight: 400;
  line-height: 0.625rem;
}

@mixin typography-text-5 {
  font-family: var(--theme-font-family-base);
  font-size: 0.625rem;
  font-weight: 400;
  line-height: 135%;
}

@mixin typography-text-4 {
  font-family: var(--theme-font-family-base);
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 135%;
}

@mixin typography-text-3 {
  font-family: var(--theme-font-family-base);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 135%;
}

@mixin typography-text-2 {
  font-family: var(--theme-font-family-base);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.25rem;
}

@mixin typography-text-1 {
  font-family: var(--theme-font-family-base);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.75rem;
}

@mixin typography-text-0 {
  font-family: var(--theme-font-family-base);
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.125rem;
}

@mixin typography-input {
  font-family: var(--theme-font-family-base);
  font-weight: 400;
  font-size: 0.875rem;
  line-height: normal;
}

@mixin typography-header-bullit {
  font-family: var(--theme-font-family-base);
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.25rem;
}

@mixin typography-label {
  font-family: var(--theme-font-family-base);
  font-size: 0.625rem;
  font-weight: 600;
  line-height: 0.875rem;
}
