@import 'mixins/typography';
@import 'breakpoints';
@import 'utilities';

html {
  font-size: 16px;
}

html,
body {
  margin: 0;
  padding: 0;
  min-height: 100%;
  font-family: var(--theme-font-family-base, 'Open Sans');
  text-rendering: optimizeLegibility;
  box-sizing: border-box;

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &._overflow {
    overflow: hidden;
    position: relative;
    height: 100%;
  }
}

* > * {
  box-sizing: border-box;
}

body {
  --viewport-height: 100vh;
  font-size: 0.875rem;
  line-height: normal;
  color: var(--theme-text-main);
}

/* total width */
::-webkit-scrollbar {
  background: inherit;
  width: 12px;
  height: 12px;

  @media screen and (max-width: 899px) {
    width: 0;
  }
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background: var(--grey-3);
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: #9d9d9d;

  @media screen and (max-width: 899px) {
    width: 0;
  }

  &:vertical {
    border-right: 4px solid rgba(0, 0, 0, 0);
  }

  &:horizontal {
    border-bottom: 4px solid rgba(0, 0, 0, 0);
  }
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7 {
  margin: 0;
}

h1 {
  @include typography-h1;
}

h2 {
  @include typography-h2;
}

h3 {
  @include typography-h3;
}

h4 {
  @include typography-h5;
}

h6 {
  @include typography-h8;
}

h7 {
  @include typography-h9;
}

button {
  color: inherit;
}

a {
  color: inherit;
  text-decoration: none;
}

.notice-word {
  position: relative;
  z-index: 1;
  font-weight: 600;
  display: inline-block;

  &::before {
    content: '';
    position: absolute;
    width: calc(100% + 0.25rem);
    height: 100%;
    background: #fdf8e1;
    left: -0.125rem;
    top: 0;
    bottom: 0;
    z-index: -1;
    border-radius: 0.25rem;

    @include media-breakpoint-up(md) {
      border-radius: 0.5rem;
    }

    @include media-breakpoint-up(xl) {
      border-radius: 0.625rem;
    }
  }
}

.app-gallery-item {
  &._not-empty-src {
    cursor: pointer;
  }
}

.font-currency {
  font-family: var(--theme-font-family-currency) !important;
}

._mirror-horizontal {
  transform: scaleX(-1);
}

app-checkbox {
  a {
    text-decoration: underline;
  }
}

@each $breakpoint in map-keys($breakpoints) {
  // Generate media query if needed
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $breakpoints);

    // Loop over each utility property
    @each $key, $utility in $utilities {
      // The utility can be disabled with `false`, thus check if the utility is a map first
      // Only proceed if responsive media queries are enabled or if it's the base media query
      @if type-of($utility) == 'map' and (map-get($utility, responsive) or $infix == '') {
        @include generate-utility($utility, $infix);
      }
    }
  }
}

.heading {
  color: var(--theme-text-heading) !important;
}
