@import "breakpoints";

.container {
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: auto;
  margin-right: auto;

  @include media-breakpoint-up(md) {
    max-width: 759px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 976px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1172px;
  }

  @include media-breakpoint-up(xxl) {
    max-width: 1232px;

    &._large {
      max-width: 1552px;
    }
  }
}
