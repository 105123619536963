.news-post-content {
  @include typography-text-3;
  color: var(--theme-text-secondary);
  white-space: pre-wrap;
  word-wrap: break-word;
  white-space-collapse: preserve;

  a {
    color: var(--theme-accent);
    text-decoration: none;
  }

  .mention,
  .more-link {
    font-weight: 600;
  }

  .post-subtitle {
    color: var(--theme-text-main);
  }

  .emoji {
    width: 16px;
    height: 16px;
    border: 0;
    vertical-align: -3px;
    margin: 0 1px;
    display: inline-block;
    overflow: hidden;
    content: '';
  }
}
