.snack-bar-container {
  margin: 0.5rem;
  position: static;
  flex: 1 1 auto;
  border-radius: 0.25rem;
  min-width: inherit;
  max-width: inherit;
  width: 100%;

  white-space: pre-line;
}

.snackbar {
  min-width: 21.5rem;
  max-width: 42rem;

  &__surface {
    background: white;
    box-shadow: -1px 3px 20px 1px #212C3726;
    border-radius: 0.5rem;
  }

  &__label {
    padding: 1rem;
  }

  &._success {
    .snackbar__surface {
      background-color: var(--theme-alert-success-bg);
      border: 1px solid var(--theme-alert-success-color);
    }

    .snackbar__label {
      color: var(--theme-alert-success-color);
    }
  }

  &._danger {
    .snackbar__surface {
      background-color: var(--theme-alert-danger-bg);
      border: 1px solid var(--theme-alert-danger-color);
    }

    .snackbar__label {
      color: var(--theme-alert-danger-color);
    }
  }

  &._warning {
    .snackbar__surface {
      background-color: var(--theme-alert-warn-bg);
      border: 1px solid var(--theme-alert-warn-color);
    }

    .snackbar__label {
      color: var(--theme-alert-warn-color);
    }
  }

  &._info {
    .snackbar__surface {
      background-color: var(--theme-alert-info-bg);
      border: 1px solid var(--theme-alert-info-color);
    }

    .snackbar__label {
      color: var(--theme-alert-info-color);
    }
  }
}
