.app-skeleton {
  opacity:0;
  animation-name: skeleton-pulse;
  animation-duration: 1.5s;
  animation-direction: normal;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

  background: var(--theme-skeleton-bg) !important;
  display: block;
  max-width: 100%;
  min-width: 0;
  border-radius: 0.25rem;
}

.skeleton-card {
  opacity: 0;
  animation-name: skeleton-show;
  animation-duration: 0.25s;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes skeleton-pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes skeleton-show {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
