svg-icon {
  display: block;
  width: var(--icon-size, 1rem);
  height: var(--icon-size, 1rem);
  font-size: var(--icon-size, 1rem);
  line-height: var(--icon-size, 1rem);

  svg {
    display: block;
    stroke: currentColor;
    fill: currentColor;
    width: 100%;
    height: 100%;
  }

  &._small {
    --icon-size: 1rem;
  }

  &._medium {
    --icon-size: 1.5rem;
  }

  &._large {
    --icon-size: 2rem;
  }
}
