@import 'typography';
@import 'breakpoints';

table {
  min-width: 100%;
  width: auto;
  border-collapse: collapse;

  .cdk-header-cell {
    text-align: left;
    padding: 0.75rem 0.5rem;
    @include typography-h10;
    background: var(--grey-4);

    @include media-breakpoint-up(md) {
      padding: 1rem 0.5rem;
    }

    &:not(:last-child) {
      border-right: 1px solid var(--grey-2);
    }

    &:first-child {
      border-top-left-radius: 0.5rem;
    }

    &:last-child {
      border-top-right-radius: 0.5rem;
    }
  }

  .cdk-cell {
    @include typography-text-4;
    height: 3.125rem;
    padding: 0.625rem;

    border-left: 1px solid var(--grey-bg);
    border-right: 1px solid var(--grey-bg);
    border-bottom: 1px solid var(--grey-bg);
  }

  .cdk-row {
    &._clickable {
      cursor: pointer;

      @include media-breakpoint-up(md) {
        &:hover {
          .cdk-cell {
            background: var(--grey-3);
          }
        }
      }
    }
  }

  .cdk-column-expandedDetail {
    height: auto;
    padding: 0 2.75rem;

    @include media-breakpoint-up(lg) {
      padding: 0 2.75rem 0 1.625rem;
    }
  }
}
