color-picker {
  pointer-events: auto;

  .color-picker {
    border-radius: 0.5rem;
    background: white;
    box-shadow: 2px 0 40px 0 #22222233;
    border: none;
    overflow: hidden;

    .saturation-lightness {
      border-radius: 0.25rem;
      overflow: hidden;
    }

    .cursor {
      border: 2px solid white;
      box-shadow: 0 4px 6px 0 #1F29371A, 0 2px 4px 0 #1F29370F;
    }

    .hue, .alpha {
      height: 0.625rem;
      border-radius: 6.25rem;

      .cursor {
        border-width: 1px;
        width: 0.625rem;
        height: 0.625rem;
      }
    }
  }
}
