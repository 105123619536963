body {
  --theme-font-family-base: 'Open Sans';
  --theme-font-family-currency: 'Noto Sans';
  --theme-font-family-logo-subtitle: 'Sofia Sans';

  --grey-bg: #f4f4f4;
  --grey-2: #e8e8e8;
  --grey-2-2: #efefef;
  --grey-3: #f7f8fa;
  --grey-4: #f0f2f7;
  --grey-5: #bbbbbb;

  --no-photo-bg-color: #c5cfda;

  --theme-skeleton-bg: #eff1f6;

  /* Text */
  --theme-text-main: #13191c;
  --theme-text-secondary: #475051;
  --theme-text-secondary-2: #738297;
  --theme-text-secondary-3: #939191;
  --theme-text-tretiary: #828c8e;
  --theme-text-heading: #13191c;
  --theme-text-error: #fc0b19;
  --theme-text-dark: #13191c;
  --theme-dark-light: #dee5ed;
  --theme-light-green: #dcf5f5;
  --theme-yellow: #fffbe8;
  --theme-blue: #066d81;
  --theme-red: #ee4748;

  --theme-accent: #039999;

  --theme-link: var(--theme-accent, #039999);

  /* Alerts */
  --theme-alert-danger-bg: #f1dbda;
  --theme-alert-danger-color: #a90e46;

  --theme-alert-info-bg: #e0e0e0;
  --theme-alert-info-color: var(--theme-text-main);

  --theme-alert-success-bg: #d5ecd6;
  --theme-alert-success-color: #1f8b24;

  --theme-alert-warn-bg: #f0e5d7;
  --theme-alert-warn-color: #bf710f;

  --theme-info-panel-color: #818d93;

  /** Buttons */
  --theme-flat-button-border-radius: 1.75rem;
  --theme-flat-button-primary-bg: var(--theme-accent);
  --theme-flat-button-primary-color: var(--grey-4);
  --theme-flat-button-primary-border: transparent;
  --theme-flat-button-primary-hover-bg: var(--theme-flat-button-primary-bg);
  --theme-flat-button-primary-hover-color: var(--theme-flat-button-primary-color);
  --theme-flat-button-primary-hover-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-hover-opacity: 0.75;
  --theme-flat-button-primary-disabled-bg: var(--grey-2-2);
  --theme-flat-button-primary-disabled-color: var(--grey-5);
  --theme-flat-button-primary-disabled-border: var(--theme-flat-button-primary-border);
  --theme-flat-button-primary-disabled-opacity: 1;

  --theme-flat-button-white-bg: white;
  --theme-flat-button-white-color: var(--theme-text-main);
  --theme-flat-button-white-border: transparent;
  --theme-flat-button-white-disabled-bg: var(--grey-2);
  --theme-flat-button-white-disabled-color: #ffffff;
  --theme-flat-button-white-disabled-border: var(--theme-flat-button-white-border);
  --theme-flat-button-white-disabled-opacity: 1;

  --theme-flat-button-secondary-bg: var(--grey-4);
  --theme-flat-button-secondary-color: var(--theme-text-main);
  --theme-flat-button-secondary-border: transparent;
  --theme-flat-button-secondary-hover-bg: var(--theme-dark-light);
  --theme-flat-button-secondary-hover-color: var(--theme-accent);
  --theme-flat-button-secondary-hover-border: transparent;
  --theme-flat-button-secondary-hover-opacity: 1;
  --theme-flat-button-secondary-active-bg: var(--theme-flat-button-secondary-hover-bg);
  --theme-flat-button-secondary-active-color: var(--theme-flat-button-secondary-hover-color);
  --theme-flat-button-secondary-active-border: var(--theme-flat-button-secondary-hover-border);
  --theme-flat-button-secondary-disabled-bg: var(--theme-flat-button-secondary-bg);
  --theme-flat-button-secondary-disabled-color: var(--theme-flat-button-secondary-color);
  --theme-flat-button-secondary-disabled-border: var(--theme-flat-button-secondary-border);
  --theme-flat-button-secondary-disabled-opacity: 1;

  /* Inputs */
  --theme-input-border: var(--grey-2, #e8e8e8);
  --theme-input-placeholder: var(--theme-text-secondary, #939191);
  --theme-input-valid: var(--theme-accent, #039999);
  --theme-input-error-border: var(--theme-red, #ee4748);

  --theme-checkbox-border: var(--theme-text-secondary-2, #738297);

  /* Components */
  --theme-profile-card-radius: 1rem;
  --theme-profile-card-box-shadow: 4px 4px 10px 0px #22222214, -2px -3px 14px 0px #22222214;
}
