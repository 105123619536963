/* You can add global styles to this file, and also import other style files */
@use "@angular/cdk" as cdk;

@import "normalize.css";
@import '@ctrl/ngx-emoji-mart/picker';

@include cdk.overlay;
@include cdk.text-field;
@include cdk.a11y-visually-hidden;

@import "cropperjs";

@import "styles/fonts";

@import "styles/general";
@import "styles/themes/default";

@import "styles/components";
