@import "typography";
@import "breakpoints";

input, textarea {
  font-size: inherit;
  font-style: normal;
  font-weight: inherit;
  line-height: inherit;
  border: none;
  outline: none;
  resize: none;

  &::placeholder {
    color: var(--theme-input-placeholder, #939191);
    @include typography-text-3;
  }
}

.form-field {
  width: 100%;
  position: relative;
  display: block;
  --form-field-label-floating-transform: translate(0, -1.35rem) scale(0.86);

  @include media-breakpoint-up(lg) {
    --form-field-label-floating-transform: translate(0, -1.75rem) scale(0.86);
  }


  &.ng-touched._error {
    .form-field-box {
      border-color: var(--theme-input-error-border);
    }
  }

  .form-field-on-valid {
    color: var(--theme-input-valid);
  }

  &:not(.ng-touched._valid), &.ng-pristine {
    .form-field-on-valid {
      display: none;
    }
  }

  .form-field-on-invalid {
    color: var(--theme-text-secondary);
  }

  &:not(.ng-touched._error) {
    .form-field-on-invalid {
      display: none;
    }
  }

  &._hide-placeholder {
    input, textarea {
      &::placeholder {
        opacity: 0;
      }
    }
  }
}

.form-field-label-wrapper {
  position: absolute;
  top: 1rem;
  left: var(--label-offset-left, unset);
  max-width: calc(100% - 1.25rem);

  transition: transform 0.25s ease;

  transform: translate(0, 0);
  transform-origin: 0 50%;

  @include media-breakpoint-up(lg) {
    top: 1.25rem;
  }

  &._floating {
    transform: var(--form-field-label-floating-transform);
  }

  &._has-bg {
    &::before {
      content: "";
      display: block;
      position: absolute;
      width: calc(100% + 0.5rem);
      height: 100%;
      background: var(--form-field-label-bg, white);
      border-radius: 0.25rem;
      top: 0;
      left: -0.25rem;
    }
  }

  label {
    color: var(--theme-text-secondary);
    white-space: nowrap;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 100%;
    position: relative;
    z-index: 1;
  }
}

.form-field-box {
  width: 100%;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;
  gap: 0 0.625rem;
  border-radius: 0.5rem;
  border: 1px solid var(--theme-input-border);
  position: relative;

  input, textarea {
    padding: 0;
    background: none;
    border: none;
    border-radius: 0;
    display: block;
    width: 100%;

    @include typography-input;
    color: var(--theme-text-main);
  }

  textarea {
    overflow: hidden;
  }


  .form-field-prefix, .form-field-suffix {
    flex: 0 0 auto;
    display: flex;
    align-items: center;
  }
}

.form-field-infix {
  padding: 1rem 0;
  flex: 1 1 auto;
  cursor: text;

  @include media-breakpoint-up(lg) {
    padding: 1.25rem 0;
  }

  &._no-padding-bottom {
    padding-bottom: 0;
  }
}

.form-field-error {
  display: flex;
  align-items: center;
  gap: 0 0.375rem;
  margin-top: 0.25rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;

  color: var(--theme-text-error);
  font-size: 0.75rem;
  font-weight: 400;
  text-align: left;
}

.form-field-hint {
  padding-top: 0.5rem;
  @include typography-text-5;
  color: var(--theme-text-secondary);
}
